import React from 'react';
import './home-page.css'

import CarouselItems from './carousel-items'
import CareItems from './care-items'
import ProductItems from './product-items'


function HomePage(){
    return(
        <div>
             <CarouselItems />
             <ProductItems />
             <CareItems />
        </div>
    )
}

export default HomePage