import React from 'react';
import { Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
import '../../App.css'

function CarouselItems(){
    const scrollToAnchor = () => {
        let anchorElement = document.getElementById('screens');
        if(anchorElement) { anchorElement.scrollIntoView(); }
    }
    
    return(
        <Carousel className="carousel-style">
            <Carousel.Item >
                <div className="carousel-image carousel-item-one" ></div>
            </Carousel.Item>
            <Carousel.Item >
                <div className="carousel-image carousel-item-two"></div>
                <Carousel.Caption className="carousel-caption-two">
                    <a href="#product-details/2" onClick={()=> scrollToAnchor()}><Button variant="primary">了解详情</Button></a>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item >
                <div className="carousel-image carousel-item-three"></div>
                <Carousel.Caption  className="carousel-caption-three">
                    <a href="#product-details/1" onClick={()=> scrollToAnchor()}><Button variant="primary">了解详情</Button></a>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default CarouselItems