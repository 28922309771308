import './App.css';
import React, { useEffect, useState } from 'react';
import {Route, HashRouter as Router, Redirect} from 'react-router-dom'

import TopNavBar from './components/common/top-nav-bar';
import FooterBar from './components/common/footer-bar';
import HomePage from './components/home-page/home-page';
import ProductDetails from './components/product-details/product-details';
import CareDetails from './components/care-details/care-details';
import CompanyIntroduct from './components/company-introduct/company-introduct';

function App() {
  function useScrollPosition() {
    const [scrollPosition, setScrollPosition] = useState(0);
    // 页面加载完成之后，做个监听。
    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.scrollY)
      }
      document.addEventListener("scroll", handleScroll);
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    }, [])
    return scrollPosition;
  }

  const position = useScrollPosition();

  return (
    <div className="zimin-style">
      
        <Router>
          <TopNavBar />
          <div className={position > 80 ? "context-padding" : ""}>
            <Route path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home" component={HomePage}/>
            <Route path="/company" component={CompanyIntroduct}/>
            <Route path="/care-details/:id">
              <CareDetails />
            </Route>
            <Route path="/product-details/:id">
              <ProductDetails />
            </Route>
              
          </div>
          <FooterBar />
        </Router>
      
      
    </div>
  );
}

export default App;
