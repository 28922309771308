import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Button, Jumbotron } from 'react-bootstrap';

function CareItems(){
    const scrollToAnchor = () => {
        let anchorElement = document.getElementById('screens');
        if(anchorElement) { anchorElement.scrollIntoView(); }
    
    }

    return(
        <div>
            
            <h2 className="sub-title">肌肤护理</h2>
            <Container style={{marginBottom: '8rem'}}>
                <Row>
                    <Col style={{marginBottom: '2rem'}}>
                        <div className="care-item-one care-item-img">
                            <Jumbotron className="care-item-bg">
                                
                                    <h1>婴幼儿常见皮肤疾病及其护理</h1>
                                    
                                    <p>
                                        <a href="#care-details/1" onClick={()=> scrollToAnchor()}><Button variant="primary">查看全文</Button></a>
                                    </p>
                                
                                
                            </Jumbotron>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={6} style={{marginBottom: '2rem'}}>
                        <div className="care-item-two care-item-img">
                            <Jumbotron className="care-item-bg">
                                <h1>湿疹宝宝护肤要点</h1>
                                <p>
                                    <a href="#care-details/2" onClick={()=> scrollToAnchor()}><Button variant="primary">查看全文</Button></a>
                                </p>
                            </Jumbotron>
                        </div>
                    </Col>
                    <Col sm={12} lg={6} style={{marginBottom: '2rem'}}>
                        <div className="care-item-three care-item-img">
                            <Jumbotron className="care-item-bg">
                                <h1>敏感肌肤护理</h1>
                                <p>
                                    <a href="#care-details/3" onClick={()=> scrollToAnchor()}><Button variant="primary">查看全文</Button></a>
                                </p>
                            </Jumbotron>
                        </div>
                    </Col>
                </Row>
            </Container>
                
            
        </div>
    )
}

export default CareItems