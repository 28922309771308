import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './company-introduct.css'

function CompanyIntroduct(){
    const companyintro = require('../../assets/companyintro.jpg').default;

    return(
        <div>
            <Container>
                <Row>
                    <Col className="company-intro-col">
                        <img src={companyintro} alt="" style={{width: '100%', marginBottom: '80px'}}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CompanyIntroduct