import React from 'react';
import './care-details.css'


function CareItemThird(){

    return(
        <div>
            <div className="care-body-style">
                <p>新生宝宝的皮肤非常敏感，宝宝的皮肤厚度比成人低得多，并且角质层薄弱，对抗外界环境的耐受性相对比较差，稍不留心就容易出现红肿、皮疹、干痒等皮肤问题。因此，就需要宝爸宝妈们格外的用心护理才行。</p>

                <ul>
                    <li>
                        <p className="care-third-title">1. 务必注意宝宝皮肤的清洁</p>
                        <p>有的妈妈为了让宝宝干净卫生，天天洗澡，宝宝皮脂保护层洗掉很容易让皮肤干燥、变敏感。最好根据具体情况和天气调整宝宝洗澡频次，一般每周三次即可。少量用宝宝专用沐浴露可以在宝宝皮肤上留下一层保护膜，增强皮肤的屏障功能。腋窝、耳后等皮肤褶皱处千万别忽视。洗澡水温比体温稍高即可，再高的话会加重宝宝皮肤干燥的程度。</p>
                        <p>给宝宝洗澡要注意水温、时长。如果爸爸妈妈给孩子用了润肤露，但宝宝的皮肤仍然很干的话，那可能是因为你给孩子洗澡洗的太频繁了。因为如果洗澡时间太长、次数太多、水温过高等，都可能导致婴儿体内的水分流失，从而导致皮肤干燥。</p>
                        <p>如果存在这种问题的话，家长不妨少给宝宝洗几次澡，同时在每次洗澡以后，都给孩子涂上润肤露，并帮宝宝按摩均匀，这样能让孩子感到很舒服。</p>
                    </li>
                    <li>
                        <p className="care-third-title">2. 务必给宝宝选用合适的润肤乳</p>
                        <p>很多家长在给宝宝洗澡的时候，会犯这样一个错误，在给宝宝洗完澡之后，只是简单的帮他擦干水渍，并不会给他涂上润肤乳。这样做是非常不正确的，因为年纪很小的宝宝，他们的皮肤非常娇嫩。不涂润肤乳就给他穿衣服的话，很容易会损伤宝宝的皮肤。建议各位家长给宝宝洗完澡之后，一定要及时的帮他涂上润肤乳。在购买润肤乳的时候也要多注意，尽量选择那种无香味、无色素、配方温和的。这样既滋润了宝宝的肌肤，又不会损害到他的健康。</p>
                        <p>因为普通护肤品中常常含有香料等化学物质，可能对婴儿的皮肤产生刺激，所以妈妈千万别拿自己的保湿护肤品给婴儿用，只能用婴儿专用的护肤品。</p>
                        <p>选择护肤品时，成分越简单越好，不会给宝宝皮肤带来额外的刺激。润肤霜的话，没有酒精、色素、香精是必须。沐浴露无皂配方适宜。洗发水则以无泪配方为首选。至于湿巾的话，如果要用，就选择不含酒精的。</p>
                    </li>
                    <li>
                        <p className="care-third-title">3. 务必给宝宝选择抗敏洗衣液</p>
                        <p>宝宝的体温调节功能还不完善，体温高，容易出汗。各种衣物、被褥或贴身用品，要勤洗勤换，避免细菌的滋生。宝宝的用品要单独洗涤，使用柔性的抗敏洗液，多次冲洗，阳光处晾晒。尤其需要贴身穿着使用的衣物，在宝宝上身之前务必清洗晾晒过。</p>
                        <p>各位家长除了要经常给宝宝更换衣服之外，还要把洗过的衣服放在阳光处晾晒，这样做不仅能起到更好的杀菌效果，还可以让衣服变得更加柔软。</p>
                    </li>
                    <li>
                        <p className="care-third-title">4. 务必要做好宝宝防晒</p>
                        <p>适当的接受阳光照晒能帮助宝宝补充维生素D，能让身体更好的吸收钙和磷。可宝宝皮肤色素少，对阳光中紫外线的抵抗能力小，皮肤容易灼伤，防晒必不可少。</p>
                        <p>宝宝的防晒以物理方式为主，着透气长袖衣裤、戴宽舌帽和防晒镜，避开在阳光照射强烈的时间出门。</p>
                    </li>
                    <li>
                        <p className="care-third-title">5. 务必注意宝宝营养要全面</p>
                        <p>宝宝的皮肤健康状况，和身体的营养情况有关。营养全面、不偏食的宝宝，皮肤润泽、有弹性，自身新陈代谢也更好。皮肤的健康既少不了热量和蛋白质，也需要充足的维生素。但鱼虾之类的高蛋白海产品容易引发皮肤的过敏症状，在给宝宝食用时需要特别观察。</p>
                    </li>
                    <li>
                        <p className="care-third-title">6. 务必注意发现问题及时就医</p>
                        <p>如果爸爸妈妈发现孩子身上的皮肤干燥问题一直得不到改善，甚至还有发展得更严重的趋势，那就应该及时咨询儿科医生的意见，防止皮肤湿疹等问题的产生，给宝宝带来跟严重的伤害。</p>
                        <p>每一个宝宝都是纯净的天使，需要宝爸宝妈们细心呵护，在过程中要多注意以上几点，不错过任何细节，只有平时的多加注意，才会给宝宝一个更加安全的环境，让他们健康茁长成长。</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CareItemThird