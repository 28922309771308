import React, { useRef, useState } from 'react';
import './common.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const scrollToAnchor = () => {
    let anchorElement = document.getElementById('screens');
    if(anchorElement) { anchorElement.scrollIntoView(); }
}

function FooterBar(){
    const friendUrl = require('../../assets/saoma1.jpg').default;
    const logoUrl = require('../../assets/logo.png').default;
    const hongShuUrl = require('../../assets/hongshu.png').default;
    const weixinUrl = require('../../assets/weixin.png').default;
    const weiboUrl = require('../../assets/weibo.png').default

    const [show, setShow] = useState(false);
    const target = useRef(null);

    let history = useHistory();

    return(
        <div>
            <div className="footer-bg">
              
                <Container>
                    <Row className="d-none d-lg-block">
                        <Col>
                        <img
                            src={logoUrl}
                            width="110"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={12}>
                            <ul className="footer-menu">
                                <li onClick={()=> {history.push('/company');scrollToAnchor();}} style={{cursor: 'pointer'}}>走进滋敏</li>
                            </ul>
                            
                        </Col>

                        <Col lg={6} md={12} className="sub-info-right">
                            <p className="sub-info d-none d-lg-block">
                                <OverlayTrigger
                                    key={'left'}
                                    placement={'left'}
                                    overlay={
                                        <Tooltip id={`tooltip-${'left'}`} className="footer-friend-bg">
                                            <img src={friendUrl} width="180" height="180" alt=""/>
                                        </Tooltip>
                                    }>
                                    <img src={weixinUrl} height="35" alt="" style={{paddingRight:"10px",paddingBottom:"5px",cursor:"pointer"}} />
                                </OverlayTrigger>
                                <img src={weiboUrl} height="35" alt="" style={{paddingRight:"14px",paddingBottom:"5px",cursor:"pointer"}} onClick={()=> window.open("https://weibo.com/u/2355346803", "_blank")} />
                                <img src={hongShuUrl} height="25" alt="" style={{paddingBottom:"5px",cursor:"pointer"}} onClick={()=> window.open("https://www.xiaohongshu.com/user/profile/5fb3098c0000000001001f7a?xhsshare=CopyLink&appuid=5ae7e631e8ac2b33ef5e0658&apptime=1609727009", "_blank")}/>
                            </p>
                            <p className="sub-info d-block d-lg-none sub-mb-show">
                                <img src={weixinUrl} height="35" alt="" style={{paddingRight:"10px",paddingBottom:"5px",cursor:"pointer"}} ref={target} onClick={() => setShow(!show)} />
                                <Overlay target={target.current} show={show} placement="top">
                                    {(props) => (
                                    <Tooltip id="overlay-example" {...props}  className="footer-friend-mb">
                                        <img src={friendUrl} width="150" height="150" alt=""/>
                                    </Tooltip>
                                    )}
                                </Overlay>
                                <img src={weiboUrl} height="35" alt="" style={{paddingRight:"14px",paddingBottom:"5px",cursor:"pointer"}} onClick={()=> window.open("https://weibo.com/u/2355346803", "_blank")} />
                                <img src={hongShuUrl} height="25" alt="" style={{paddingBottom:"5px",cursor:"pointer"}} onClick={()=> window.open("https://www.xiaohongshu.com/user/profile/5fb3098c0000000001001f7a?xhsshare=CopyLink&appuid=5ae7e631e8ac2b33ef5e0658&apptime=1609727009", "_blank")}/>
                            </p>
                        </Col>
                    </Row> 
                    {/*<Row> 
                        <Col>
                            <p className="sub-info d-none d-lg-block">
                                <OverlayTrigger
                                    key={'right'}
                                    placement={'right'}
                                    overlay={
                                        <Tooltip id={`tooltip-${'right'}`} className="footer-friend-bg">
                                            <img src={friendUrl} width="180" height="180" alt=""/>
                                        </Tooltip>
                                    }>
                                    <i className="fa fa-weixin" style={{fontSize:"25px", color:"rgba(255,255,255,.8)"}}></i>
                                </OverlayTrigger>
                            </p>
                            <p className="sub-info d-block d-lg-none sub-mb-show">
                                <i className="fa fa-weixin" style={{color:"rgba(255,255,255,.8)"}} ref={target} onClick={() => setShow(!show)}></i>
                                <Overlay target={target.current} show={show} placement="top">
                                    {(props) => (
                                    <Tooltip id="overlay-example" {...props}  className="footer-friend-mb">
                                        <img src={friendUrl} width="150" height="150" alt=""/>
                                    </Tooltip>
                                    )}
                                </Overlay>
                            </p>
                        </Col>
                                    </Row>*/}
                </Container>
                
            </div>
        </div>
    )
}

export default FooterBar