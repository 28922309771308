import React from 'react';
import './care-details.css'


function CareItemSecond(){
    const careItemBody = [
        {
            index: 1,
            title: "接触性皮炎(口水疹)",
            body1: "年龄：0~6岁",
            body2: "部位：皮肤接触致敏物的地方",
            body3: "外形：皮肤局部变痒发红甚至出现小水泡",
            body4: "护理：1避免反复接触刺激物 2与湿疹护理类似"
        }, 
        {
            index: 2,
            title: "婴儿脂溢性皮炎(乳痂，头泥)",
            body1: "年龄：3周~3个月宝宝，大部分会在6个月内消退",
            body2: "部位：头部，面部(眉部)",
            body3: "外形：皮肤轻微发红，黏着油腻的黄色厚麟屑，形成一层疮痂，一般不痒或轻微瘙痒",
            body4: "护理：1清水清洁皮肤 2可用油类（橄榄油，婴儿抚触油）软化头泥 3涂保湿霜让皮肤湿润"
        },
        {
            index: 3,
            title: "新生儿痤疮",
            body1: "年龄：出生3个月以内，2周左右最常见",
            body2: "部位：额头和面颊等部位",
            body3: "外形：细小凸起的红疹，“青春痘”样皮损，但一般没有粉刺",
            body4: "护理：通常在出生后3个月内自然消失，无需特别治疗，不留瘢痕"
        },
        {
            index: 4,
            title: "热疹(热痱，痱子)",
            body1: "年龄：各年龄段",
            body2: "部位：颈部，背部，前胸等",
            body3: "外形：因汗液积聚在皮肤上引起的细小凸起的红疹",
            body4: "护理：1清水清洁皮肤 2衣服适量，减少流汗"
        }];

    const subItems = [
        {
            index: 1,
            title: "（1）皮肤瘙痒难耐，推荐使用抗组胺药",
            body1: "推荐：西替利嗪滴剂，氯雷他定糖浆",
            body2: "不推荐：炉甘石（使用后皮肤更干燥，加重痒感）",
            body3: ""
        }, 
        {
            index: 2,
            title: "（2）皮肤破损感染时，需要抗感染治疗",
            body1: "细菌感染：莫匹罗星软膏（百多邦）或红霉素软膏",
            body2: "真菌感染：派瑞松",
            body3: ""
        },
        {
            index: 3,
            title: "（3）中重度湿疹在医生的指导下首推外用糖皮质激素，家长一听到激素就紧张，湿疹指南推荐：激素药膏是湿疹的一线治疗方法，合理使用激素药膏并不会对孩子造成影响。",
            body1: "药效：激素药膏也分弱效、中效，强效等不同强度药效的产品，选择时，应根据不同身体部位对应使用，遵医嘱使用。比如皮肤褶皱、眼周部位，对药膏吸收率比较高，使用时应以弱效药为主。",
            body2: "用量：以食指第一个指关节为度量，将药膏挤到指尖的位置，一指尖单位的用药对应的湿疹区域大概是两个手掌面的范围（成人），建议家长根据这个面积换算一下，孩子大概需要的药量；",
            body3: "使用时间：面部连续使用不超过2周，褶皱、会阴敏感部位不超过1周，都是安全的；对于四肢、躯干的湿疹，使用到湿疹消退都是没问题的。在湿疹逐渐好转的过程中，应在医生的指导下逐渐减少用量，家长最好不要自行减药或停用。划重点："
        }];

        const instructions = [
            {
                index: 1,
                context: "选择软膏（ointment）或者乳膏/霜（cream），不要选择洗剂（lotion）；"
            }, 
            {
                index: 2,
                context: "不含香精的；"
            },
            {
                index: 3,
                context: "防腐剂和添加剂少的；"
            },
            {
                index: 4,
                context: "大品牌的；"
            },
            {
                index: 5,
                context: "给孩子试用后没问题的。"
            }];

        const instructionNext = [
            {
                index: 1,
                context: "软膏或者乳膏，这里面含的油多，所以保湿效果好，软膏的含油量更多，效果优于乳膏，不要使用洗剂，因为洗剂是液体的，容易蒸发，在蒸发后可能会感到凉爽，让人舒服，但会导致皮肤更干，所以不要使用。另外，如果家长接受的话，还可以使用纯油性的，比如白凡士林等，保湿效果更好。"
            }, 
            {
                index: 2,
                context: "人工香精是有刺激性的，所以不要使用含香精的保湿霜。有些保湿霜闻起来有香味，但是里面是天然的植物或油的香味，是可以使用的。"
            },
            {
                index: 3,
                context: "保湿霜内有防腐剂是可以的，因为疫苗、食物中都含有防腐剂，所以保湿霜里有点是没问题的，当然没有的最好。添加剂也推荐是越少越好，一款保湿霜，除了保湿成分，其他的固化剂、溶剂等成分，在保证功能的基础上，越少越好。有些保湿霜会添加一些橄榄、大豆精油、燕麦成分等，这也有可能会增加孩子的皮肤敏感和过敏的风险，所以，如果孩子皮肤比较敏感或者湿疹较重的，不要使用含有这些的保湿霜。"
            },
            {
                index: 4,
                context: "大品牌的的保湿霜一般研发能力强，经过市场验证后还能卖的非常好，那就一般没有问题。具体可以把保湿霜涂在宝宝的手背或者小腿处，看是否有发红，孩子是否有觉得不舒服的表现，如果有的话，需要换一种再用。"
            }];

    return(
        <div>
            <div className="care-body-style">
                <p>婴儿湿疹分为三种类型：</p>
                <p>（1）干燥型（轻度）多见于6个月～1岁婴儿。常见春季和冬季，因为天气干燥或空气干燥，导致宝宝皮肤严重缺水而引起的。皮损表现为丘疹、红肿、硬性糠皮样鳞屑及结痂，常见于面部、躯干和四肢侧伸面。</p>
                <p>（2）渗出型（中度）多见于3～6个月婴儿，尤其是肥胖的婴儿。脖子,腿弯，腋下等各关节处常见，可见对称性小米粒大小红色丘疹，间有小水疱和红斑，底部水肿，片状糜烂，有黄色浆液渗出。</p>
                <p>（3）脂溢型（重度）多见于3个月内婴儿。患儿前额、颊部和眉间皮肤潮红，有黄色油腻性鳞屑，头顶可有较厚的黄色液痂。严重时，颏下、后颈、腋及腹股沟可有擦烂、潮红及渗出。</p>
                <p>宝爸和宝妈如何和湿疹鉴别呢？</p>

                {
                    careItemBody.map((item) => {
                        return (
                            <div key={item.index} className="care-section">
                                <p className="care-section-title">{item.title}</p>
                                <p>{item.body1}</p>
                                <p>{item.body2}</p>
                                <p>{item.body3}</p>
                                <p>{item.body4}</p>
                            </div>
                        )
                    })
                } 

                <div className="care-little-section">
                    <p className="care-little-title" style={{fontWeight: 'normal'}}>婴儿湿疹居家护理小妙招</p>
                    
                    <p className="care-subitem">
                        <span className="title-color">1. 皮肤保湿：</span>保湿！保湿！保湿！重要的事情说三遍，保湿是湿疹最基本也是最重要的居家护理，不仅发病时使用，平时也应常规使用，如果宝宝皮肤干燥，可每三小时一次，足量厚涂。推荐使用润肤乳（不含香精或色素的）保湿，如果处于秋冬季节，室内环境干燥，选择质地较稠的润肤膏或霜，保湿性、持久性更好；如果处于春夏季节，考虑舒适度，选择质地较稀薄的润肤乳。
                        <span className="title-color">轻中度湿疹</span>，可选择含有甘油、透明质酸、神经酰胺等帮助皮肤保湿和修复皮肤屏障作用成分的润肤霜（乳）；
                        <span className="title-color">中重度湿疹</span>，在医生的指导下，选择含有抗菌肽等抑菌成分的特殊功效型润肤霜（乳）。
                    </p>
                    <div className="care-subitem">
                        <p><span className="title-color">2. 合理用药：</span>根据引发炎症不同的原因，在医生的指导下合理用药：</p>
                        {
                            subItems.map((item) => {
                                return (
                                    <div key={item.index}>
                                        <p>{item.title}</p>
                                        <p>{item.body1}</p>
                                        <p>{item.body2}</p>
                                        <p>{item.body3}</p>
                                    </div>
                                )
                            })
                        }
                        <p style={{fontWeight: 'normal'}}>#建议先涂抹药膏，后涂抹润肤霜。家长在为宝宝涂抹药膏时可以轻轻按摩一下，观察到药膏吸收了就可以涂抹润肤霜了，不需要等待太长时间。</p>
                    </div>
                    <p className="care-subitem">
                        <span className="title-color">3. 衣物：</span>给宝宝穿纯棉、宽松、柔软的衣物。避免穿化纤织物，羊毛织物和绒线毛衫。在不着凉的前题下尽量少穿。
                    </p>
                    <p className="care-subitem">
                        <span className="title-color">4. 洗澡：</span>每天洗一次澡，水温不要太高，最好不要超过36℃，洗澡时间不宜超过10分钟，选择温和的沐浴露，一周使用1~2次即可，频繁使用会加重宝宝皮肤干燥。勿用毛巾搓澡，以免破坏皮肤屏障。洗澡后3分钟内使用保湿剂，锁住水分。
                    </p>
                    <p className="care-subitem">
                        <span className="title-color">5. 饮食：</span>婴儿湿疹多数与饮食无关，一般无需过度忌口，但进食试验明确者，应尽量避免。母乳喂养的妈妈也不需要盲目忌口，可以观察并记录下自己的饮食，如果发现吃了某种食物，孩子的湿疹明显加重，停掉会减轻，那么考虑可能和这种食物有关系，建议暂停3-6个月左右再次尝试；但如果说妈妈不管吃什么，孩子的湿疹都没有什么变化，就无需忌口。哺乳期注意营养均衡。
                    </p>
                    <p className="care-subitem">
                        <span className="title-color">6. 避免搔抓：</span>剪短宝宝指甲，以防抓伤皮肤。
                    </p>
                    <p className="care-subitem">
                        <span className="title-color">7. 牛奶蛋白过敏：</span>对于婴儿来说最常见的是牛奶蛋白过敏，如果湿疹反复发生且面积比较大，建议可尝试更换深度水解奶粉或者氨基酸奶粉，在这期间哺乳期妈妈应暂停食用牛奶及其制品。
                    </p>
                    <p className="care-subitem">
                        <span className="title-color">8. 如果宝宝湿疹非常严重：</span>建议到呼吸专科门诊就诊排除是否有先天性免疫疾病。
                    </p>
                    
                </div>
                
                <div className="care-little-section">
                    <p className="care-little-title">如何正确使用保湿剂</p>
                    <p>保湿霜涂到皮肤上后，可以增加角质层的含水量，增加皮肤的屏障功能，防止外界的刺激原进入皮肤内部产生过敏或炎症，其作用在湿疹整体治疗中，重要性占60-70%，大家一定要用好保湿霜。</p>
                    <p style={{marginBottom: '0px'}}>
                        <span className="title-color">1. 保湿剂的选择：</span>对于湿疹的儿童，我们该如何选择呢？
                    </p>
                    <p>下面我们给出选择原则：</p>
                    <ul>
                    {
                        instructions.map((item) => {
                            return (
                                <li key={item.index}><span style={{marginRight:'5px'}}>●</span> {item.context}</li>
                            )
                        })
                    }
                    </ul>
                    <p>下面具体说说,</p>
                    <ul className="care-subitem">
                    {
                        instructionNext.map((item) => {
                            return (
                                <li key={item.index}><span style={{marginRight:'5px'}}>●</span> {item.context}</li>
                            )
                        })
                    }
                    </ul>
                    <p className="care-subitem">
                        <span className="title-color">2. 保湿霜量怎么定？</span>保湿剂推荐大量使用，用量不足的话达不到给皮肤保湿的效果。欧洲2012特异性皮炎指南中，对于儿童湿疹，推荐可以每周使用150-200g保湿霜，大家按照自己宝宝的情况来使用，确保孩子的皮肤一直处于水润的状态。
                    </p>
                    <p className="care-subitem">
                        <span className="title-color">3. 保湿霜每天使用次数呢？</span>建议每天使用次数至少是2次，家长可以用到每日4-5次，发现孩子皮肤一干就涂抹，效果更好。
                    </p>
                    <p className="care-subitem">
                        <span className="title-color">4. 怎么涂抹？</span>按照美国湿疹协会的推荐，不要用手直接从瓶子里把保湿霜挖出来，这样会污染剩下的保湿霜。把保湿霜弄到手掌里，双手互相搓几下，把保湿霜弄软，之后在皮肤湿疹处，从上到下的涂抹保湿霜，不要上上下下的来回揉，不要画圈揉，怕刺激皮肤。建议把保湿霜涂得厚些，这样更有保湿锁水效果。如果觉得发粘，不要清理，一会就能吸收了。

                    </p>
                    <p className="care-subitem">
                        <span className="title-color">5. 什么时候停？</span>等湿疹完全好了，最好也不要停保湿霜了，可以每天一次的使用，保证皮肤润润的，如果在南方湿度比较大，不涂抹皮肤也润润的时候，那就不要涂抹了。
                    </p>
                    
                </div>

            </div>
        </div>
    )
}

export default CareItemSecond