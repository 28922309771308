import React from 'react';
import './product-details.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';


interface ParamTypes {
    id: string;
}

function ProductDetails(){
    const productUrl1 = require('../../assets/prodetail3.jpg').default;
    const productUrl2 = require('../../assets/homepro3.jpg').default;

    const { id } = useParams<ParamTypes>();
    const index = parseInt(id) - 1;

    const productInfo = [
        {
            imageUrl: productUrl1,
            name: "婴幼儿舒缓滋养精华霜",
            size: "150g",
            effect: "深度滋养，长效保湿、增强皮肤屏障",
            feature: "皮肤屏障修护、深度滋养保湿",
            element: "神经酰胺1、3、6-II，克拉玛斯湖藻"
        }, 
        {
            imageUrl: productUrl2,
            name: "婴幼儿舒缓保湿修护霜",
            size: "50g",
            effect: "快速有效抗炎 、止痒 、舒缓红肿",
            feature: "有效抗炎、止痒、舒缓祛红、修复屏障",
            element: "神经酰胺1、3、6-II，密罗木提取物，复合植物抗敏剂"
        }];

    return(
        <div>
            <Container style={{marginBottom: '25rem'}} className="product-details-style">
                <Row style={{margin: '0 auto'}}>
                    <Col lg={5} md={12}><img src={productInfo[index].imageUrl} style={{width: '100%', paddingTop: '4.5rem'}} alt="" /></Col>
                    <Col lg={7} md={12} style={{paddingLeft: '2rem'}}>
                        <p className="product-name">{productInfo[index].name}</p>
                        <p className="product-size">规格<span>{productInfo[index].size}</span></p>

                        <Accordion defaultActiveKey="0" className="accordion-section">
                            {/*<Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                规格
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>50g</Card.Body>
                                </Accordion.Collapse>
                            </Card>*/}
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                功效
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>{productInfo[index].effect}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                特点
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>{productInfo[index].feature}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                成分
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>{productInfo[index].element}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ProductDetails
