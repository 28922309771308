import React from 'react';
import './home-page.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';


function ProductItems(){
    const productUrl1 = require('../../assets/homepro1.jpg').default;
    const productUrl2 = require('../../assets/homepro2.jpg').default;

    let history = useHistory();

    const scrollToAnchor = () => {
        let anchorElement = document.getElementById('screens');
        if(anchorElement) { anchorElement.scrollIntoView(); }
    
    }

    return(
        <div>
             <div style={{position: 'relative'}}>
                <div className="product-bg">
                </div>
                <div className="sub-product-wrapper">
                    <h2 className="sub-title">产品</h2>
                    <Container style={{marginBottom: '7rem'}} >
                        <Row>
                            <Col lg={1} md={0}></Col>
                            <Col lg={10} md={12} className="d-none d-md-block">
                                
                                <ul className="products">
                                    <li className="product-item pro-left-item" onClick={()=> {history.push('/product-details/1');scrollToAnchor();}}>
                                        <div className="product-style">
                                            <div className="product-title-wrapper">
                                                <div className="product-image">
                                                    <img src={productUrl1} alt="" />
                                                </div>
                                                <div className="product-info">
                                                    <p className="product-name">婴幼儿舒缓滋养精华霜</p>
                                                    <p className="product-detail">鲜奶油般柔润乳霜质地，肌肤24小时保湿滋润，温和无香，特别添加克拉玛斯湖藻，深度滋养，保湿抗氧化</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="product-item" onClick={()=> {history.push('/product-details/2');scrollToAnchor();}}>
                                        <div className="product-style">
                                            <div className="product-title-wrapper">
                                                <div className="product-image">
                                                    <img src={productUrl2} alt="" />
                                                </div>
                                                <div className="product-info">
                                                    <p className="product-name">婴幼儿舒缓保湿修护霜</p>
                                                    <p className="product-detail">富含天然保湿修复成分密罗木提取物，舒缓祛红，快速止痒，深度保湿，针对湿疹、皮肤干燥及皮肤屏障受损引起的皮肤问题均有明显作用</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                               <svg viewBox="0 0 600 600" className="iyrEFf">
                                    <g transform="translate(300,300)">
                                        <path d="M82,-98.3C129.8,-57.7,208.2,-54.1,229.6,-26.6C251.1,1,215.6,52.5,172.9,74.9C130.2,97.2,80.3,90.4,37.8,107.5C-4.8,124.7,-40,165.7,-63.3,162.3C-86.5,158.8,-97.8,110.9,-109.3,72.2C-120.7,33.5,-132.2,4.1,-140.1,-37.4C-147.9,-79,-152.1,-132.6,-128.1,-177.3C-104.1,-222.1,-52.1,-258,-17.5,-237.2C17.1,-216.4,34.3,-138.9,82,-98.3Z" fill="currentColor"></path>
                                    </g>
    </svg>
     {/*<svg width="600" height="600" viewBox="0 0 600 600" className="hsMAjX"><g transform="translate(300,300)"><path d="M125,-160.4C159.9,-146.7,184.6,-107.3,178.8,-70.9C173.1,-34.4,137,-0.8,128.8,47.3C120.7,95.5,140.6,158.2,123.8,198.4C107,238.6,53.5,256.3,5.4,248.9C-42.7,241.5,-85.4,208.9,-112.7,172.1C-140,135.3,-151.8,94.2,-146.3,59.7C-140.8,25.1,-117.9,-2.9,-104,-30.1C-90.1,-57.3,-85,-83.7,-69.1,-103.5C-53.3,-123.4,-26.6,-136.7,9.2,-149.4C45.1,-162,90.1,-174,125,-160.4Z" fill="#FFB4BC"></path></g></svg>*/}
                                <svg width="600" height="600" viewBox="0 0 600 600" className="ktdZfb"><g transform="translate(300,300)">
                                    <path d="M176.2,-106.9C208.7,-45.5,201.8,33.5,165.8,99.4C129.9,165.3,65,218.2,-9.2,223.5C-83.4,228.8,-166.9,186.7,-201.6,121.4C-236.4,56.2,-222.6,-32.2,-180.9,-98.9C-139.1,-165.7,-69.6,-210.8,1.2,-211.5C71.9,-212.2,143.8,-168.3,176.2,-106.9Z" fill="#6415ff"></path></g></svg>
                            </Col>
                            <Col md={12} xs={12} className="box1 d-block d-md-none">
                                <Carousel className="mob-carousel-style" interval={null}>
                             
                                    <Carousel.Item >
                                        <ul className="products">
                                            <li className="product-item pro-left-item" style={{paddingTop: '20px'}}  onClick={()=> {history.push('/product-details/1');scrollToAnchor();}}>
                                                <div className="product-style">
                                                    <div className="product-title-wrapper">
                                                        <div className="product-image">
                                                            <img src={productUrl1} alt="" />
                                                        </div>
                                                        <div className="product-info">
                                                            <p className="product-name">婴幼儿舒缓滋养精华霜</p>
                                                            <p className="product-detail">鲜奶油般柔润乳霜质地，肌肤24小时保湿滋润，温和无香，特别添加克拉玛斯湖藻，深度滋养，保湿抗氧化</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Carousel.Item>
                                    <Carousel.Item >
                                        <ul className="products">
                                            <li className="product-item" style={{paddingTop: '20px'}}  onClick={()=> {history.push('/product-details/2');scrollToAnchor();}}>
                                                <div className="product-style">
                                                    <div className="product-title-wrapper">
                                                        <div className="product-image">
                                                            <img src={productUrl2} alt="" />
                                                        </div>
                                                        <div className="product-info">
                                                            <p className="product-name">婴幼儿舒缓保湿修护霜</p>
                                                            <p className="product-detail">富含天然保湿修复成分密罗木提取物，舒缓祛红，快速止痒，深度保湿，针对湿疹、皮肤干燥及皮肤屏障受损引起的皮肤问题均有明显作用</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                            <Col  lg={1} md={0}></Col>
                        </Row>
                    </Container>
                    
                    {/*<div className="box1 d-block d-sm-none">
                        <div className="wrap">
                            <ul className="products">
                                <li className="product-item pro-left-item" style={{paddingLeft: '20px',paddingTop: '20px'}}  onClick={()=> history.push('/product-details/1')}>
                                    <div className="product-style">
                                        <div className="product-title-wrapper">
                                            <div className="product-image">
                                                <img src={productUrl1} alt="" />
                                            </div>
                                            <div className="product-info">
                                                <p className="product-name">婴幼儿舒缓滋养精华霜</p>
                                                <p className="product-detail">鲜奶油般柔润乳霜质地，肌肤24小时保湿滋润，温和无香，特别添加克拉马斯蓝藻，深度滋养，保湿抗氧化</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="product-item" style={{paddingRight: '20px',paddingTop: '20px'}}  onClick={()=> history.push('/product-details/2')}>
                                    <div className="product-style">
                                        <div className="product-title-wrapper">
                                            <div className="product-image">
                                                <img src={productUrl2} alt="" />
                                            </div>
                                            <div className="product-info">
                                                <p className="product-name">婴幼儿舒缓保湿修护霜</p>
                                                <p className="product-detail">富含天然保湿修复成分密罗木提取物，舒缓祛红，快速止痒，深度保湿，针对湿疹、皮肤干燥及皮肤屏障受损引起的皮肤问题均有明显作用</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        
                    </div>*/}

                </div>
             </div>
           
        </div>
    )
}

export default ProductItems