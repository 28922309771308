import React from 'react';
import './care-details.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import CareItemFirst from './care-item-first'
import CareItemSecond from './care-item-second'
import CareItemThird from './care-item-third'

interface ParamTypes {
    id: string;
}

function CareDetails(){
    const careUrl = require('../../assets/2.png').default;
    const careUrl1 = require('../../assets/7.jpg').default;
    const careUrl2 = require('../../assets/5.jpg').default;
    const careUrl3 = require('../../assets/1.jpg').default;

    const { id } = useParams<ParamTypes>();
    const index = parseInt(id) - 1;

    const careInfo = [
        {
            index: 1,
            imageUrl: careUrl1,
            title: "婴幼儿常见皮肤疾病及其护理"
        }, 
        {
            index: 2,
            imageUrl: careUrl2,
            title: "湿疹宝宝护肤要点"
        },
        {
            index: 3,
            imageUrl: careUrl3,
            title: "敏感肌肤护理"
        }];

    let history = useHistory();

    const scrollToAnchor = () => {
        let anchorElement = document.getElementById('screens');
        if(anchorElement) { anchorElement.scrollIntoView(); }
    
    }

    return(
        <div>
            <div className="care-bg">
                <p>健康肌专题</p>
                <img src={careUrl} alt=""/>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <p className="care-item-title">{careInfo[index].title}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8}>
                            <img src={careInfo[index].imageUrl} className="care-item-image" alt=""/>
                            <div style={careInfo[index].index === 1 ? {display: 'block'} : {display: 'none'} }><CareItemFirst /></div>
                            <div style={careInfo[index].index === 2 ? {display: 'block'} : {display: 'none'} }><CareItemSecond /></div>
                            <div style={careInfo[index].index === 3 ? {display: 'block'} : {display: 'none'} }><CareItemThird /></div>
                            
                        </Col>
                        <Col lg={4}>
                            <Container className="other-care">
                                <Row>
                                    <Col>
                                        <p style={{fontSize: '20px'}}>其他健康肌专题</p>
                                    </Col>
                                </Row>
                                {
                                    careInfo.map((item) => {
                                        return (
                                            <Row 
                                                key={item.index} 
                                                style={item.index === index+1 ? {display: 'none'} : {display: 'block', marginBottom: '20px', cursor: 'pointer'} } 
                                                onClick={()=> {history.push(`/care-details/${item.index}`); scrollToAnchor()}}>
                                                <Col>
                                                    <img src={item.imageUrl} className="care-item-image" alt="" />
                                                </Col>
                                                <Col>
                                                    <p>{item.title}</p>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }        
                            </Container>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CareDetails
