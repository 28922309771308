import React from 'react';
import './care-details.css'


function CareItemFirst(){
    const careItemBody = [
        {
            index: 1,
            title: "痱子无害的粟丘疹",
            body1: "病症表现：在新生儿前额、脸颊、眼、鼻周围，甚至外耳处，有1 ～ 2 毫米大小的黄白色丘疹，表面光滑呈球状，其顶端尖圆，上覆极薄表皮，用手挤压可见坚实的角质样球状颗粒。粟丘疹多于出生时或生后不久开始出现，可见于正常的新生儿。",
            body2: "发病原因：新生儿粟丘疹由未发育的皮脂腺形成，可自行消退。",
            body3: "宝宝状态：不疼不痒，无不良反应。",
            body4: "护理要点：无需治疗，大约在1 个月左右消退，也有可能存在达3 个月之久。"
        }, 
        {
            index: 2,
            title: "捂出来的痱子",
            body1: "病症表现：在新生儿的额头、两颊、颈部、腋下等身体褶皱处，出现红色突起的小疹子。发疹部位皮肤发红，严重时有组织液的渗出，天气热或穿太多时会更明显。",
            body2: "发病原因：新生儿汗腺发育不完全，体温调节能力差，由于汗液排泄不畅而潴积于皮内引起汗腺周围发炎。",
            body3: "宝宝状态：宝宝会感到刺痒，特别是当衣物摩擦疹子时。",
            body4: "护理要点：可用柔软的布蘸温水擦拭，等待自然干燥，同时需保证室内空气的流通。预防及治疗：不能给宝宝穿过多衣物，就算天气冷也不能包裹太严实。若宝宝容易发汗，可多冲几次澡，擦上痱子粉，但不宜擦得过多。疹子消退后也许会在两三个月内反复出现。照上述做法处理，隔几天症状会自动消退。"
        },
        {
            index: 3,
            title: "传染的脓疱病",
            body1: "病症表现：有些出生4 ～ 10 天的新生儿，突然在脸上、胸背部、四肢或皮肤褶皱处，出现很小的丘疹，丘疹迅速长大成黄豆大小的大疱，里面充满黄色或浑浊的液体。脓疱高于皮肤表面，周围微微发红，破裂后露出红色糜烂面，伴有臭味。液体流出之后水疱表面结痂，周围则出现新的脓点。患病较轻的宝宝，长疱的面积比较小，并且不再发生新疮；而较重的则不断出现新疮。",
            body2: "发病原因：脓疱病是一种传染性很强的皮肤病，由葡萄球菌或链球菌引起。大多数孩子身上都带有这类细菌，一旦皮肤破损，细菌就会侵入而发病。",
            body3: "宝宝状态：宝宝会感到瘙痒和疼痛，病重时可能伴有发热、呕吐、腹泻等症状。",
            body4: "护理要点：应立即就医。此病传染性强，一发现就要马上隔离，并对宝宝的衣物和房间进行消毒。妈妈、保姆等密切接触者护理前必须洗手并戴口罩。为了预防这种病， 需注意给宝宝勤洗澡勤换衣，避免衣物摩擦损伤皮肤。"
        },
        {
            index: 4,
            title: "常见的脂溢性皮炎",
            body1: "病症表现：新生儿会在两颊、头皮、耳朵后侧、眉毛等处出现类似油垢的黄色硬痂，严重时身体也会结痂，皮肤红肿。",
            body2: "发病原因：发病的原因目前尚不明确，可能与皮脂腺分泌过多有关。",
            body3: "宝宝状态：宝宝会感到很痒，不舒服。",
            body4: "护理要点：洗澡前在宝宝头上先涂点婴儿油，等痂皮软化后再轻轻抓洗，此时头发通常也会跟着脱落。两颊和耳后常用温水清洗，痂皮如已松动可用手轻轻剥掉。若结痂严重，就需要带宝宝上医院就诊。"
        },
        {
            index: 5,
            title: "男宝宝独有的痤疮",
            body1: "病症表现：新生儿的额头、脸等处出现黑头粉刺、丘疹，有时也形成脓疱。",
            body2: "发病原因：宝宝在出生前从母体获得了过多的雄性激素，促使皮脂腺分泌出过多的皮脂淤积在毛囊内，使皮肤形成粉刺样毛囊性丘疹。",
            body3: "宝宝状态：没有明显的不良反应。",
            body4: "护理要点：患上新生儿痤疮的几乎全是男婴，症状轻的不需要治疗会自然消退。如果症状比较严重，就要带宝宝去医院就诊。"
        },
        {
            index: 6,
            title: "良性的新生儿毒性红斑",
            body1: "病症表现：约有半数的新生儿在出生3 天以内发疹。身体和四肢发生大小不定的红斑，边界不清，鲜红湿润，红斑中心或周围可能出现1 ～ 2 毫米大小的黄白色丘疹，摸起来硬硬的，有时也有少数小脓疱。",
            body2: "发病原因：病因不明，可能为胃肠道吸收某种致敏原或因母体内分泌的激素经胎盘或乳汁进入新生儿机体内引起变异反应所致。",
            body3: "宝宝状态：没有明显的不良反应。",
            body4: "护理要点：新生儿毒性红斑，名字听上去很吓人，其实是不需要任何治疗即可自行消退的良性皮肤病。一般在一周内退掉。"
        },
        {
            index: 7,
            title: "多发的血管瘤",
            body1: "病症表现：常见不规则块状红斑，多发生在上眼皮、后颈部、两颊或额头。有些血管瘤则高于皮肤，呈突起的块状，摸着软软的。",
            body2: "发病原因：由血管组织发生的肿瘤称为血管瘤，其中80%属先天性的。血管瘤属于良性的，生长缓慢，很少恶变。",
            body3: "宝宝状态：没有明显的不良反应。",
            body4: "护理要点：血管瘤在新生儿中发生率比较高，大部分是良性的，不需要特别处理，在宝宝7 ～ 9 岁时会完全消退。但如果长在眼皮、嘴巴边等神经分布丰富的地方，或某些会影响美观的部位，需立即就医。"
        },
        {
            index: 8,
            title: "胎记—“蒙古斑”",
            body1: "病症表现：在新生儿腰背部和屁股上常见淡灰青色或暗青色斑块，大小不一，可能呈现一个较大的斑印，或者多个较小的斑块。",
            body2: "发病原因：胚胎在发育过程中一些黑色素细胞停留在真皮层延迟消失造成的，是一种良性的先天胎记。",
            body3: "宝宝状态：没有明显的不良反应。",
            body4: "护理要点：这是非常常见的一种胎记，通常在2 ～ 5 年内自行退去，不需要治疗。"
        },
        {
            index: 9,
            title: "风疹块(荨麻疹)",
            body1: "病症表现：很多新生儿身上会出现一种疹块，高于皮肤表面，大小不等，颜色为红色，并伴有很快出现又很快消失的白色小斑点。",
            body2: "发病原因：最有可能引起荨麻疹的是过敏，这也许与宝宝本身的过敏体质有关。另外一个可能的原因是昆虫叮咬。",
            body3: "宝宝状态：整个出疹过程只持续一周左右，除非反复发作，一般不需要治疗。因为患荨麻疹时会发痒、肿胀，宝宝也许因此哭闹。但需特别注意的是，如果发疹的同时伴有呼吸困难、眼睑或嘴唇水肿，你应该立即带宝宝就诊。",
            body4: "护理要点：应注意宝宝房间环境卫生，避免接触过敏原，如避免接触宠物、花粉等。"
        }];

    return(
        <div>
            <div className="care-body-style">
                <p>婴幼儿的皮肤又薄又嫩，护理时需要格外小心。随时细致观察，才能敏锐地发现异常。当宝宝的皮肤出现问题时，你应该知道哪些病症是良性的，什么时候应当去看医生。</p>

                {
                    careItemBody.map((item) => {
                        return (
                            <div key={item.index} className="care-section">
                                <p className="care-section-title">{item.title}</p>
                                <p>{item.body1}</p>
                                <p>{item.body2}</p>
                                <p>{item.body3}</p>
                                <p>{item.body4}</p>
                            </div>
                        )
                    })
                }   
            </div>
        </div>
    )
}

export default CareItemFirst