import React, { useEffect, useState } from 'react';
import './common.css'

import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/esm/NavDropdown';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

interface subNavProps{
    subNavInfo: any, 
    showProductList: boolean,
    setShowProductList: Function,
    inProductList: boolean,
    setInProductList: Function,
    subNavItem: any,
    setSubNavItem: Function
}

const scrollToAnchor = () => {
    let anchorElement = document.getElementById('screens');
    if(anchorElement) { anchorElement.scrollIntoView(); }
}

const SubNavMenu: React.FC<subNavProps> = (props: any) =>{
    const {subNavInfo, setShowProductList, setInProductList, subNavItem, setSubNavItem} = props;

    return (
        <Container>
            <Row>
                <Col lg={6} className="sub-left-nav">
                {
                    subNavInfo.map((item: any, index: number) => {
                        return (
                            <span key={index} onMouseEnter={()=> setSubNavItem(item)} onClick={()=> {setShowProductList(false);setInProductList(false)}}>
                                <a href={item.link}  onClick={()=> scrollToAnchor()}>{item.name}</a>
                            </span>
                        )
                    })
                }     
                </Col>
                <Col lg={5} className="sub-right-nav">
                    <a href={subNavItem.link} onClick={()=> scrollToAnchor()}>
                        <img src={subNavItem.url} onClick={()=> {setShowProductList(false);setInProductList(false)}} alt="" />
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

function TopNavBar(){
    const iconUrl = require('../../assets/wx-icon.png').default
    const friendUrl = require('../../assets/saoma1.jpg').default
    const logoUrl = require('../../assets/logo.png').default
    const productUrl1 = require('../../assets/product1.png').default;
    const productUrl2 = require('../../assets/product2.png').default;
    const careUrl1 = require('../../assets/7.jpg').default;
    const careUrl2 = require('../../assets/5.jpg').default;
    const careUrl3 = require('../../assets/1.jpg').default;

    const [showProductList, setShowProductList] = useState(false);
    const [inProductList, setInProductList] = useState(false);

    const subNavProductInfo = [
        {
            url: productUrl1,
            link: "#product-details/1",
            name: "婴幼儿舒缓滋养精华霜"
        }, 
        {
            url: productUrl2,
            link: "#product-details/2",
            name: "婴幼儿舒缓保湿修护霜"
        }];

    const subNavCareInfo = [
        {
            url: careUrl1,
            link: "#care-details/1",
            name: "婴幼儿常见皮肤疾病及其护理"
        }, 
        {
            url: careUrl2,
            link: "#care-details/2",
            name: "湿疹宝宝护肤要点"
        },
        {
            url: careUrl3,
            link: "#care-details/3",
            name: "敏感肌肤护理"
        }];

    const [subNavInfo, setSubNavInfo] = useState(subNavProductInfo);
    const [subNavItem, setSubNavItem] = useState(subNavProductInfo[0]);

    function useScrollPosition() {
        const [scrollPosition, setScrollPosition] = useState(0);
        // 页面加载完成之后，做个监听。
        useEffect(() => {
          const handleScroll = () => {
            setScrollPosition(window.scrollY)
          }
          document.addEventListener("scroll", handleScroll);
          return () => {
            document.removeEventListener("scroll", handleScroll)
          }
        }, [])
        return scrollPosition;
    }

    const position = useScrollPosition();

    return(
        <div id="screens">
            {position <= 80 ? 
            <Navbar expand="lg" className="nav-top-image d-none d-lg-block">
                <Navbar.Brand href="#home" className="brand-logo" onClick={()=> scrollToAnchor()}>
                    <img
                        src={logoUrl}
                        width="130"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    /> 
                </Navbar.Brand>
            </Navbar> : ""}
            <Navbar 
                expand="lg" 
                className={position <= 80 ? "shadow-only-bottom" : "shadow-only-bottom fixed-top top-bar-shadow"}
                style={position <= 80 ? {boxShadow: '0px 6px 7px -7px #a9a4a49e'} : {}}>
                <Navbar.Brand href="#home" className="brand-logo d-none d-lg-block">
                    <img
                        src={logoUrl}
                        width="115"
                        height="43"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo" 
                        style={position <= 80 ? {visibility:'hidden'} : {visibility:'visible'}}
                        onClick={()=> scrollToAnchor()}
                    />
                </Navbar.Brand>
                <Navbar.Brand href="#home" className="brand-logo d-block d-lg-none">
                    <img
                        src={logoUrl}
                        width="115"
                        height="43"
                        className="d-inline-block align-top"
                        alt="React Bootstrap" 
                        onClick={()=> scrollToAnchor()}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    
                    <Nav  style={{marginRight:'15px'}} className="mr-auto menu-links">
                        <Nav.Link 
                            className="d-none d-lg-block" 
                            onMouseEnter={()=> {setShowProductList(true); setSubNavInfo(subNavProductInfo); setSubNavItem(subNavProductInfo[0])}} 
                            onMouseLeave={()=> {setShowProductList(false); setSubNavInfo(subNavProductInfo)}}>
                            <span>全部产品</span>
                        </Nav.Link>
                        <NavDropdown title="全部产品" id="basic-nav-dropdown" className="d-block d-lg-none">
                            {
                                subNavProductInfo.map((item,index) => {
                                    return (
                                        <NavDropdown.Item href={item.link} key={index} onClick={()=> scrollToAnchor()}>{item.name}</NavDropdown.Item>
                                    )
                                })
                            }
                        </NavDropdown>
                        <Nav.Link 
                            className="d-none d-lg-block" 
                            onMouseEnter={()=> {setShowProductList(true); setSubNavInfo(subNavCareInfo); setSubNavItem(subNavCareInfo[0])}} 
                            onMouseLeave={()=> {setShowProductList(false); setSubNavInfo(subNavCareInfo)}}>
                            <span>健康肌专题</span>
                        </Nav.Link>
                        <NavDropdown title="健康肌专题" id="basic-nav-dropdown" className="d-block d-lg-none">
                            {
                                subNavCareInfo.map((item,index) => {
                                    return (
                                        <NavDropdown.Item href={item.link} key={index} onClick={()=> scrollToAnchor()}>{item.name}</NavDropdown.Item>
                                    )
                                })
                            }
                        </NavDropdown>
                        <Nav.Link href="#company" onClick={()=> scrollToAnchor()}><span>走进滋敏</span></Nav.Link>
                    {/**/}
                        
                    </Nav>
                    <Nav className="wxicon-menu">
                        <Navbar.Text>
                            <OverlayTrigger
                            key={'left'}
                            placement={'left'}
                            overlay={
                                <Tooltip id={`tooltip-${'left'}`} className="friend-bg">
                                    <img src={friendUrl} width="180" height="180" alt=""/>
                                </Tooltip>
                            }>
                                <div style={{ backgroundImage: "url(" + iconUrl+ ")"}} className="weixin-icon"></div>
                            </OverlayTrigger>
                        </Navbar.Text>
                    </Nav>
                    {/*<Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-success">Search</Button>
                    </Form>
                    
                    style={showProductList || inProductList ? {display: "block"} : {display: "none"}} onMouseEnter={()=> setInProductList(true)} onMouseLeave={()=> setInProductList(false)}
                    */}
                </Navbar.Collapse>
            
            </Navbar>

            
            <div 
                className={position <= 80 ? "sub-nav" : "sub-nav sub-nav-top"}
                style={showProductList || inProductList ? {display: "block"} : {display: "none"}} 
                onMouseEnter={()=> setInProductList(true)} 
                onMouseLeave={()=> setInProductList(false)}>
                <SubNavMenu 
                    subNavInfo={subNavInfo} 
                    showProductList={showProductList} 
                    setShowProductList={setShowProductList} 
                    inProductList={inProductList} 
                    setInProductList={setInProductList}
                    subNavItem={subNavItem}
                    setSubNavItem={setSubNavItem}
                     />
                {/*<Container>
                    <Row>
                        <Col lg={6} className="sub-left-nav">
                        {
                            subNavProductInfo.map((item, index) => {
                                return (
                                    <span key={index} onMouseEnter={()=> setSubNavIndex(index)} onClick={()=> {setShowProductList(false);setInProductList(false)}}>
                                        <a href={item.link}>{item.name}</a>
                                    </span>
                                )
                            })
                        }     
                        </Col>
                        <Col lg={5} className="sub-right-nav">
                            <a href={subNavProductInfo[subNavIndex].link}>
                                <img src={subNavProductInfo[subNavIndex].url} onClick={()=> {setShowProductList(false);setInProductList(false)}} alt="" />
                            </a>
                        </Col>
                    </Row>
                </Container>*/}
                    </div>
        </div>
    )
}

export default TopNavBar